/**
 * Contenitore delle costanti dell'applicazione
 */

module CustomersinvitationsApplicationData {
    let app = angular.module("app");

    app.factory('CustomersinvitationsApplicationData', ["$translate", ($translate: angular.translate.ITranslateService) => {
        let CustomersinvitationsApplicationData = {
            constants: {
                LOGGED_USER_NOT_FOUND: "LOGGED_USER_NOT_FOUND",
                USER_NOT_AUTHORIZED: "USER_NOT_AUTHORIZED",
                USER_WITHOUT_AUTHS: "USER_WITHOUT_AUTHS",
                CUSTOMERSINVITATIONS: "CUSTOMERSINVITATIONS",
                SUBSCRIPTION_PAGE_TYPE: "SUBSCRIPTION_PAGE_TYPE",
                LETTERS_INVITE_TYPE: "LETTERS_INVITE_TYPE",
                CUSTOMERSINVITATIONS_ASSISTANT: "CUSTOMERSINVITATIONS_ASSISTANT",
                THANKSGIVING_WITH_VOUCHER_TO_PRESENTS_LETTERS_TYPE: "THANKSGIVING_WITH_VOUCHER_TO_PRESENTS_LETTERS_TYPE",
                THANKSGIVING_WITHOUT_VOUCHER_TO_PRESENTS_LETTERS_TYPE: "THANKSGIVING_WITHOUT_VOUCHER_TO_PRESENTS_LETTERS_TYPE",
                THANKSGIVING_TO_ABSENTS_LETTERS_TYPE: "THANKSGIVING_TO_ABSENTS_LETTERS_TYPE",
                INVITE_FOR_TYPOGRAPHY_COMMUNICATION_KIT_TYPE: "INVITE_FOR_TYPOGRAPHY_COMMUNICATION_KIT_TYPE",
                INVITE_COMMUNICATION_KIT_TYPE: "INVITE_COMMUNICATION_KIT_TYPE",
                PLAYBILL_COMMUNICATION_KIT_TYPE: "PLAYBILL_COMMUNICATION_KIT_TYPE",
                PLAYBILL_FOR_TYPOGRAPHY_COMMUNICATION_KIT_TYPE: "PLAYBILL_FOR_TYPOGRAPHY_COMMUNICATION_KIT_TYPE",
                MODULE_IMAGE_TYPE: "MODULE_IMAGE_TYPE",
                MODULE_EMAIL_IMAGE_TYPE: "MODULE_EMAIL_IMAGE_TYPE",
                MODULE_LANDING_PAGE_IMAGE_TYPE: "MODULE_LANDING_PAGE_IMAGE_TYPE",
                TRAINER_TYPE: "TRAINER_TYPE",
                EXTERNAL_SUPPLIER_FORM_COMM: "EXTERNAL_SUPPLIER_FORM_COMM",
                MATERIALS_TYPE: "MATERIALS",
                COURSE_DATE: "COURSE_DATE",
                EXTERNAL_OBJECT_ID: "EXTERNAL_OBJECT_ID",
                COURSE_GENERAL_INFO_STEP: "COURSE_GENERAL_INFO_STEP",
                COURSE_SCHEDULE_STEP: "COURSE_SCHEDULE_STEP",
                COURSE_MATERIALS_STEP: "COURSE_MATERIALS_STEP",
                COURSE_COMMUNICATIONS_STEP: "COURSE_COMMUNICATIONS_STEP",
                COURSE_TEMPLATE: "COURSE_TEMPLATE",
                COURSE_DATE_TEMPLATE: "COURSE_DATE_TEMPLATE",
                TEXT_TEMPLATE: "TEXT_TEMPLATE",
                CONFIRM_SUBSCRIPTION_MAIL: "CONFIRM_SUBSCRIPTION_MAIL",
                COURSE_USER_INVITE_MAIL: "COURSE_USER_INVITE_MAIL",
                ONLINE_MEETING_COURSE_USER_INVITE_MAIL: "ONLINE_MEETING_COURSE_USER_INVITE_MAIL",
                ONLINE_MEETING_CONFIRM_SUBSCRIPTION_MAIL: "ONLINE_MEETING_CONFIRM_SUBSCRIPTION_MAIL",
                ONLINE_MEETING_REMINDER_MAIL: "ONLINE_MEETING_REMINDER_MAIL",
                REFUSE_SUBSCRIPTION_MAIL: "REFUSE_SUBSCRIPTION_MAIL",
                REMINDER_MAIL: "REMINDER_MAIL",
                INVITE_SOLICIT_MAIL: "INVITE_SOLICIT_MAIL",
                PRESENT_THANKS_VOUCHER_MAIL: "PRESENT_THANKS_VOUCHER_MAIL",
                PRESENT_THANKS_MAIL: "PRESENT_THANKS_MAIL",
                ABSENT_THANKS_MAIL: "ABSENT_THANKS_MAIL",
                NEW_COURSE_DATE_CREATED_MAIL: "NEW_COURSE_DATE_CREATED_MAIL",
                ONLINE_MEETING_NEW_COURSE_DATE_CREATED_MAIL: "ONLINE_MEETING_NEW_COURSE_DATE_CREATED_MAIL",
                ONLINE_MEETING_COURSE_DATE_CLOSED_MAIL: "ONLINE_MEETING_COURSE_DATE_CLOSED_MAIL",
                COURSE_DATE_CLOSED_MAIL: "COURSE_DATE_CLOSED_MAIL",
                FREE_FORMAT_VALIDATION_REQUEST_MAIL: "FREE_FORMAT_VALIDATION_REQUEST_MAIL",
                MODULE_SCHEDULE_STEP_1: "MODULE_SCHEDULE_STEP_1",
                FREE_FORMAT_APPROVED_MAIL: "FREE_FORMAT_APPROVED_MAIL",
                FREE_FORMAT_REFUSED_MAIL: "FREE_FORMAT_REFUSED_MAIL",
                SAVE_THE_DATE: "SAVE_THE_DATE",
                EXPIRATION_WARNING: "EXPIRATION_WARNING",
                DOCUMENT: "DOCUMENT",
                VIDEO: "VIDEO",
                MODULE_SCHEDULE_STEP: "MODULE_SCHEDULE_STEP",
                MODULE_SCHEDULE_STEP_TITLE: "MODULE_SCHEDULE_STEP_TITLE",
                MODULE_SCHEDULE_STEP_DESCRIPTION: "MODULE_SCHEDULE_STEP_DESCRIPTION",
                PRESENCE: "PRESENCE",
                DRAFT: "DRAFT",
                SUPPLIER_PEOPLE_TYPE: "SUPPLIER_PEOPLE_TYPE",
                TRAINER_REQUEST: "TRAINER_REQUEST",
                FIRST_INLINE_TRAINER: "FIRST_INLINE_TRAINER",
                SECOND_INLINE_TRAINER: "SECOND_INLINE_TRAINER",
                THIRD_INLINE_TRAINER: "THIRD_INLINE_TRAINER",
                COURSE_DATE_APERITIF_DATE: "COURSE_DATE_APERITIF_DATE",
                COURSE_DATE_START_REGISTRATIONS_DATE: "COURSE_DATE_START_REGISTRATIONS_DATE",
                FIRST_EDITION_STEP: "FIRST_EDITION_STEP",
                SMART_FIRST_EDITION_STEP_DONE: "SMART_FIRST_EDITION_STEP_DONE",
                ASSIGN_SEATS_STEP_TO_AGENCIES: "ASSIGN_SEATS_STEP_TO_AGENCIES",
                ASSIGN_SEATS_STEP_TO_MANAGERS: "ASSIGN_SEATS_STEP_TO_MANAGERS",
                MONITOR_PRESENCES_MANAGER_STEP: "MONITOR_PRESENCES_MANAGER_STEP",
                MONITOR_PRESENCES_TRAINING_STEP: "MONITOR_PRESENCES_TRAINING_STEP",
                SELECT_AGENCIES_STEP: "SELECT_AGENCIES_STEP",
                SELECT_MANAGERS_STEP: "SELECT_MANAGERS_STEP",
                COMMUNICATIONS_WITH_CUSTOMER_STEP: "COMMUNICATIONS_WITH_CUSTOMER_STEP",
                MONITOR_PRESENCES_STEP: "MONITOR_PRESENCES_STEP",
                SEND_THANKS_COMMUNICATION_STEP: "SEND_THANKS_COMMUNICATION_STEP",
                // Ordine degli step per la creazione/modifica di un'edizione Smart e On Demand (solo lato Agenzia)
                SMART_OR_ON_DEMAND_EDITION_CREATED_BY_AGENCY_STEPS_ORDER: ["FIRST_EDITION_STEP", "COMMUNICATIONS_WITH_CUSTOMER_STEP", "MONITOR_PRESENCES_STEP", "SEND_THANKS_COMMUNICATION_STEP"],
                // Ordine degli step per la creazione/modifica di un'edizione Smart e On Demand (solo lato Manage di Zona)
                SMART_OR_ON_DEMAND_EDITION_CREATED_BY_ZONE_MANAGER_STEPS_ORDER: ["FIRST_EDITION_STEP", "SELECT_AGENCIES_STEP", "ASSIGN_SEATS_STEP_TO_AGENCIES", "MONITOR_PRESENCES_MANAGER_STEP"],
                // Ordine degli step per la creazione/modifica di un'edizione Direzionale (solo lato Formazione)
                DIRECTIONAL_EDITION_CREATED_BY_TRAINING_STEPS_ORDER: ["FIRST_EDITION_STEP", "SELECT_MANAGERS_STEP", "ASSIGN_SEATS_STEP_TO_MANAGERS", "MONITOR_PRESENCES_TRAINING_STEP"],
                // Ordine degli step per per la creazione/modifica di un modulo (format o direzionale) che non ha il flag "Format Web"
                MODULE_STEPS_ORDER: ["COURSE_GENERAL_INFO_STEP", "COURSE_SCHEDULE_STEP", "COURSE_MATERIALS_STEP", "COURSE_COMMUNICATIONS_STEP"],
                // Ordine degli step per per la creazione/modifica di un modulo (format o direzionale) che ha il flag "Format Web"
                ONLINE_MODULE_STEPS_ORDER: ["COURSE_GENERAL_INFO_STEP", "COURSE_MATERIALS_STEP", "COURSE_COMMUNICATIONS_STEP"],
                // Ordine degli step per per la creazione/modifica di un modulo (format o direzionale) libero
                FREE_MODULE_STEPS_ORDER: ["COURSE_GENERAL_INFO_STEP", "COURSE_SCHEDULE_STEP", "COURSE_COMMUNICATIONS_STEP"],
                COURSE_TYPE_COURSE: "COURSE",
                COURSE_TYPE_ONLINE: "ONLINE",
                COURSE_TYPE_EXTRA_COURSE: "EXTRA_COURSE",
                COURSE_TYPE_DIRECTIONAL: "DIRECTIONAL",
                COURSE_TYPE_FORMAT: "FORMAT",
                COURSE_TYPE_FREE_FORMAT: "FREE_FORMAT",
                COURSE_DATE_TYPE_DIRECTIONAL: "DIRECTIONAL",
                COURSE_DATE_TYPE_ON_DEMAND: "ON_DEMAND",
                COURSE_DATE_TYPE_SMART: "SMART",
                COURSE_DATE_TYPE_SMART_AND_ON_DEMAND: "COURSE_DATE_TYPE_SMART_AND_ON_DEMAND",
                COURSE_DATE_TYPE: "COURSE_DATE_TYPE",
                INVITE_LETTERS_TYPE: "INVITE_LETTERS_TYPE",
                SAMPLE_PATH_TYPE: "SAMPLE_PATH_TYPE",
                COURSE_DATE_AGENCY_NAME_SIGNATURE: "COURSE_DATE_AGENCY_NAME_SIGNATURE",
                COURSE_DATE_AGENCY_ADDRESS_SIGNATURE: "COURSE_DATE_AGENCY_ADDRESS_SIGNATURE",
                COURSE_DATE_AGENCY_PHONE_SIGNATURE: "COURSE_DATE_AGENCY_PHONE_SIGNATURE",
                COURSE_DATE_AGENCY_EMAIL_SIGNATURE: "COURSE_DATE_AGENCY_EMAIL_SIGNATURE",
                COURSE_DATE_AGENCY_WEBSITE_SIGNATURE: "COURSE_DATE_AGENCY_WEBSITE_SIGNATURE",
                COURSE_DATE_AGENTS: "COURSE_DATE_AGENTS",
                FIRST_TRAINER: "FIRST_TRAINER",
                SECOND_TRAINER: "SECOND_TRAINER",
                THIRD_TRAINER: "THIRD_TRAINER",
                COURSE_USER_STATUS_INSERTED: "INS",
                COURSE_USER_STATUS_INVITED: "INVITED",
                COURSE_USER_STATUS_CONFIRMED: "CONF",
                COURSE_USER_STATUS_CANCELLED: "CANCELLED",
                COURSE_USER_STATUS_PRESENT: "PRESENT",
                COURSE_USER_STATUS_ABSENT: "ABSENT",
                EMAIL_INVITATION_SENT_DATE: "EMAIL_INVITATION_SENT_DATE",
                EMAIL_THANKS_SENT_DATE: "EMAIL_THANKS_SENT_DATE",
                LETTER_INVITATION_SENT_DATE: "LETTER_INVITATION_SENT_DATE",
                LETTER_THANKS_SENT_DATE: "LETTER_THANKS_SENT_DATE",
                ENGAGEMENT_CALL_DATE: "ENGAGEMENT_CALL_DATE",
                REMIND_CALL_DATE: "REMIND_CALL_DATE",
                CONFIRM_CALL_DATE: "CONFIRM_CALL_DATE",
                COURSE_STATUS_DRAFT: "DRAFT",
                COURSE_STATUS_CONFIRMED: "CONFIRMED",
                COURSE_STATUS_CLOSED: "CLOSED",
                COURSE_STATUS_PAUSED: "PAUSED",
                COURSE_STATUS_CANCELLED: "CANCELLED",
                COURSE_STATUS_APPROVED: "APPROVED",
                COURSE_STATUS_WAITING_APPROVAL: "WAITING_APPROVAL",
                COURSE_STATUS_REFUSED: "REFUSED",
                UPLOAD_MAX_SIZE_MB: 1000,
                DIRECTIONALS_COUNTER: "directionalsCounter",
                FORMAT_COUNTER: "formatsCounter",
                TEACHERS_COUNTER: "teachersCounter",
                TEMPLATE_COUNTER: "templatesCounter",
                AGENCY: "AGENCY",
                TRAINING: "TRAINING",
                SUPERVISOR: "SUPERVISOR",
                CUSTOMERSINVITATIONS_ADMIN: "CUSTOMERSINVITATIONS_ADMIN",
                CUSTOMERSINVITATIONS_SUPERVISOR: "CUSTOMERSINVITATIONS_SUPERVISOR",
                CUSTOMERSINVITATIONS_COMMON: "CUSTOMERSINVITATIONS_COMMON",
                CUSTOMERSINVITATIONS_AGENCY: "CUSTOMERSINVITATIONS_AGENCY",
                AREA_MANAGER_ROLE_CODE: "CAR",
                ZONE_MANAGER_ROLE_CODE_ANY_CODE: "ANY_ZONE_CODE",
                COURSE_DATE_USER_CREATION: "COURSE_DATE_USER_CREATION",
                ALL: "ALL",
                INVITED: "INVITED",
                NOT_INVITED_YET: "NOT_INVITED_YET",
                NONE: "NONE",
                CONFIRMED_USERS: "CONFIRMED_USERS",
                PRESENTS_USERS: "PRESENTS_USERS",
                ABSENTS_USERS: "ABSENTS_USERS",
                SEND_INVITE: "SEND_INVITE",
                DELETE: "DELETE",
                NONE_ACTION: "NONE_ACTION",
                CHANGE_USER_STATUS_TO_CONFIRMED: "CHANGE_USER_STATUS_TO_CONFIRMED",
                CHANGE_USER_STATUS_TO_INSERTED: "CHANGE_USER_STATUS_TO_INSERTED",
                CHANGE_USER_STATUS_TO_INVITED: "CHANGE_USER_STATUS_TO_INVITED",
                CHANGE_USER_STATUS_TO_PRESENT: "CHANGE_USER_STATUS_TO_PRESENT",
                CHANGE_USER_STATUS_TO_ABSENT: "CHANGE_USER_STATUS_TO_ABSENT",
                CHANGE_USER_STATUS_TO_CANCELLED: "CHANGE_USER_STATUS_TO_CANCELLED",
                SEND_THANKSGIVIN_MAIL_TITLE: "SEND_THANKSGIVIN_MAIL_TITLE",
                TEMPLATE_NOT_FOUND: "TEMPLATE_NOT_FOUND",
                STREET_NUMBER_ADDRESS_INFO: "STREET_NUMBER_ADDRESS_INFO",
                CITY_ADDRESS_INFO: "CITY_ADDRESS_INFO",
                ROUTE_ADDRESS_INFO: "ROUTE_ADDRESS_INFO",
                POSTAL_CODE_ADDRESS_INFO: "POSTAL_CODE_ADDRESS_INFO",
                MAX_AVAILABLE_SEATS_FOR_MANAGER: "MAX_AVAILABLE_SEATS_FOR_MANAGER",
                MAX_AVAILABLE_SEATS_FOR_TRAINING: "MAX_AVAILABLE_SEATS_FOR_TRAINING",
                AGENCY_CODE: "AGENCY_CODE",
                FB_POST_COMMUNICATION_KIT_TYPE: "FB_POST_COMMUNICATION_KIT_TYPE",
                VOUCHER_PDF_TEMPLATE: "VOUCHER_PDF_TEMPLATE",
                // Lista di domini che, se contenuti nella e-mail dell'agenzia, non fanno comparire un alert per avvisare l'utente che le e-mail che costui invierà, potrebbero finire nello spam
                EMAIL_DOMAINS_NOT_SPAM: [
                    'generali.com'
                ],
                AGENCIES_MODULE_VISIBILITY: "AGENCIES_MODULE_VISIBILITY",
                TRAINING_MODULE_VISIBILITY: "TRAINING_MODULE_VISIBILITY",
                MANAGER_MODULE_VISIBILITY: "MANAGER_MODULE_VISIBILITY",
                ASSIGNED_TO: "ASSIGNED_TO",
                MORE_GENERALI_INITIATIVE: "MORE_GENERALI_INITIATIVE",
                MORE_GENERALI_INITIATIVE_MAIL: "MORE_GENERALI_INITIATIVE_MAIL",
                ORDER_BY_SURNAME_ASC: "ORDER_BY_SURNAME_ASC",
                ORDER_BY_SURNAME_DESC: "ORDER_BY_SURNAME_DESC",
                GENERIC_MAIL: "GENERIC_MAIL",
                GENERIC_NOTIFICATION: "GENERIC_NOTIFICATION",
                FREE_FORMAT_SELECTED_IMAGE: "FREE_FORMAT_SELECTED_IMAGE",
                FREE_COURSE_HIGHLIGHTED_TEXT: "FREE_COURSE_HIGHLIGHTED_TEXT",
                FREE_COURSE_SHORT_TEXT: "FREE_COURSE_SHORT_TEXT",
                INVITE_FOR_TYPOGRAPHY_VIEWED: "INVITE_FOR_TYPOGRAPHY_VIEWED",
                INVITE_COMMUNICATION_KIT_TYPE_VIEWED: "INVITE_COMMUNICATION_KIT_TYPE_VIEWED",
                PLAYBILL_FOR_TYPOGRAPHY_VIEWED: "PLAYBILL_FOR_TYPOGRAPHY_VIEWED",
                PLAYBILL_COMMUNICATION_KIT_TYPE_VIEWED: "PLAYBILL_COMMUNICATION_KIT_TYPE_VIEWED",
                INVITE_LETTERS_VIEWED: "INVITE_LETTERS_VIEWED",
                THANKS_VOUCHER_LETTERS_VIEWED: "THANKS_VOUCHER_LETTERS_VIEWED",
                THANKS_WITHOUT_VOUCHER_LETTERS_VIEWED: "THANKS_WITHOUT_VOUCHER_LETTERS_VIEWED",
                THANKS_TO_LETTERS_VIEWED: "THANKS_TO_LETTERS_VIEWED",
                SAMPLE_PATH_VIEWED: "SAMPLE_PATH_VIEWED",
                VOUCHER_PDF_VIEWED: "VOUCHER_PDF_VIEWED",
                FREE_FORMAT_IMAGES: "FREE_FORMAT_IMAGES",
                IMAGE_SET: "IMAGE_SET",
                USER: "USER",
                FREE_FORMAT_SELECTED_LANDING_IMAGE: "FREE_FORMAT_SELECTED_LANDING_IMAGE",
                FREE_FORMAT_SELECTED_EMAIL_IMAGE: "FREE_FORMAT_SELECTED_EMAIL_IMAGE",
                MODULE_IMAGE_FILENAME: "MODULE_IMAGE_FILENAME",
                MODULE_EMAIL_IMAGE_FILENAME: "MODULE_EMAIL_IMAGE_FILENAME",
                MODULE_LANDING_PAGE_IMAGE_FILENAME: "MODULE_LANDING_PAGE_IMAGE_FILENAME",
                UPLOAD: "UPLOAD",
                MODULE_SCHEDULE_STEP_TITLE_1: "MODULE_SCHEDULE_STEP_TITLE_1",
                MODULE_SCHEDULE_STEP_DESCRIPTION_1: "MODULE_SCHEDULE_STEP_DESCRIPTION_1",
                MODULE_SCHEDULE_STEP_TITLE_2: "MODULE_SCHEDULE_STEP_TITLE_2",
                MODULE_SCHEDULE_STEP_DESCRIPTION_2: "MODULE_SCHEDULE_STEP_DESCRIPTION_2",
                MODULE_SCHEDULE_STEP_TITLE_3: "MODULE_SCHEDULE_STEP_TITLE_3",
                MODULE_SCHEDULE_STEP_DESCRIPTION_3: "MODULE_SCHEDULE_STEP_DESCRIPTION_3",
                MODULE_SCHEDULE_STEP_2: "MODULE_SCHEDULE_STEP_2",
                MODULE_SCHEDULE_STEP_3: "MODULE_SCHEDULE_STEP_3",
                MODULE_ALREADY_CONFIRMED: "MODULE_ALREADY_CONFIRMED",
                MODULE_ALREADY_REFUSED: "MODULE_ALREADY_REFUSED",
                IS_FDD: "IS_FDD",
                MEETING_TYPE: "MEETING_TYPE",
                ONLINE_MEETING: "ONLINE_MEETING",
                CLASS_MEETING: "CLASS_MEETING",
                ONLINE_MEETING_PUBLIC_LINK: "ONLINE_MEETING_PUBLIC_LINK",
                ONLINE_MEETING_PUBLIC_PASSWORD: "ONLINE_MEETING_PUBLIC_PASSWORD",
                COURSE_DATE_END_DATE: "COURSE_DATE_END_DATE",
                FORMAT_TYPE: "FORMAT_TYPE",
                JUST_ONLINE: "JUST_ONLINE",
                JUST_ROOM: "JUST_ROOM",
                ROOM_OR_ONLINE: "ROOM_OR_ONLINE"
            }
        };
        return CustomersinvitationsApplicationData;
    }])
};